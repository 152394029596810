<template>
    <Dialog :modal="true" :breakpoints="{ '1000px': '60vw', '650px': '80vw' }" :style="{ width: '40vw' }"
        :header="paciente ? 'Chamar paciente ' + paciente.name : 'Carregando...'">
        <div class="w-full h-25rem border-1 border-round-xl border-400 p-5">
            <div class="flex flex-column w-full">
                <div class="flex flex-row w-full">
                    <p class="mr-1 font-semibold">Nome: </p>
                    <p class="subtitulo-dados">{{ paciente.name }}</p>
                </div>
                <div class="flex flex-row mt-2 w-full">
                    <p class="mr-1 font-semibold">Tipo do Exame: </p>
                    <p class="subtitulo-dados">{{ paciente.tipoExame }}</p>
                </div>
                <div class="flex flex-row mt-2 w-full">
                    <p class="mr-1 font-semibold">Procedimentos: </p>
                    <p class="subtitulo-dados">{{ paciente.procedimentos }}</p>
                </div>
                <div class="flex flex-row mt-2 w-full">
                    <div class="flex flex-column">
                        <p class="mr-1 font-semibold">Ambiente: </p>
                    </div>
                    <div class="flex flex-column">
                        <DropdownAmbiente
                            :class="{ 'p-invalid p-error': !ambiente, 'text-green-500': ambiente }"
                            v-model="ambiente" class="w-15rem dropdownAmbiente" id="ambiente" />
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Chamar" icon="pi pi-wifi" iconClass="rotate-90" iconPos="right"
                class="p-button-success p-button-rounded p-button-raised" @click="chamarPaciente"></Button>
        </template>
    </Dialog>
</template>
<script>
import { getClientBase } from '@/services/http'
import DropdownAmbiente from '@/components/input/DropdownAmbiente.vue';


export default {
    components: { DropdownAmbiente },
    props: {
        paciente: {
            type: Object
        },
    },
    data() {
        return {
            ambiente: null,
        };
    },
    mounted() {
        this.ambiente = this.paciente.ambiente;
    },
    emits: ['onClose', 'onLoad'],
    methods: {
        async chamarPaciente() {
            if (!this.ambiente) {
                this.$toast.add({ severity: 'info', summary: 'Você não informou seu Ambiente Atual!', detail: 'Selecione seu Ambiente Atual no campo Ambiente', life: 8000 });
                return;
            }
            try {
                const dto = {
                    paciente: this.paciente,
                    ambiente: this.ambiente
                }
                await getClientBase().patch(`/atendimento_check_in/chamar-paciente`, dto);
                this.$emit('onClose');
                this.$emit('onLoad');
                this.$toast.add({ severity: 'success', summary: 'O Paciente foi chamado!', detail: 'Seu paciente foi chamado na fila de espera', life: 8000 });
            } catch (error) {
                const mensagem  = error?.response?.data?.message ?? error?.message;
                this.$toast.add({ severity: 'error', summary: 'Ops, algo de errado aconteceu!', detail: mensagem, life: 8000 });
            }
        },
    },
}
</script>
<style scoped lang="scss">
.dropdownAmbiente {
    padding: 0px;
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border-width: 2px;
}

:deep(.p-inputtext) {
    padding: 0px;
}

:deep(.p-dropdown-label) {
    padding: 0px !important;
}
</style>