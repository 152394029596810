<template>
    <div class="card">
        <div class="flex flex-row justify-content-between">
            <div class="text-800 mb-1">
                <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                    <AppInfoManual nomeTelaDoManual="tela-chamada-profissional-list" />
                    Pacientes na Fila de Espera
                    <TourComponent tourName="tela-chamada-profissional-list"/>
                </div>
            </div>
            <div class="flex justify-content-center align-itens-center text-800 mb-1">
                <Button
                    v-if="temPermissaoAdm()"
                    label="Listagem Administrativa"
                    @click="$router.push({ name: 'tela-chamada-administrativa' })"
                    class="p-button-link p-0 m-0 ml-auto step3"
                />
            </div>
        </div>
        <div class="flex flex-row">
            <div class="step1 p-fluid mt-2 w-full md:mt-0 md:w-5 grid-nogutter">
                <DropdownAmbiente class="dropdownAmbiente" @change="loadingLoad" id="ambiente" v-model="ambiente" />
            </div>
            <div class="p-fluid mt-5 w-full md:mt-0 md:w-5 grid-nogutter">
                <Button
                    type="button"
                    v-tooltip.bottom="'Recarregar listagem'"
                    class="ml-3 step2"
                    @click="loadingLoad"
                    icon="pi pi-sync"
                    loadingIcon="pi pi-sync pi-spin"
                    :loading="loading"
                />
            </div>
        </div>
        <Divider />
        <ProgressBar v-if="loading" mode="indeterminate" style="height: 0.3em" />
        <div :style="loading ? 'opacity: 30%;' : ''">
            <div class="body">
                <DataTable class="p-datatable-sm step4" :value="atendimentos" :scrollable="true" scrollHeight="flex" responsiveLayout="stack">
                    <template #empty> Nenhum registro encontrado. </template>
                    <Column field="name" header="Paciente" :sortable="true"></Column>
                    <Column field="horarioMarcado" style="flex: 0 0 10rem" header="Horário Marcado" :sortable="true"> </Column>
                    <Column field="sala" style="flex: 0 0 10rem" header="Ambiente" :sortable="true"></Column>
                    <Column v-if="temPermissaoChamarFinalizar()" class="text-center" style="flex: 0 0 10rem">
                        <template #body="slotProps">
                            <Button
                                v-if="emAberto(slotProps.data.status)"
                                label="Chamar"
                                icon="pi pi-wifi"
                                iconClass="rotate-90"
                                iconPos="right"
                                class="p-button-success h-3rem w-full p-button-rounded p-button-raised"
                                @click="openDialogChamarPaciente(slotProps.data)"
                            ></Button>
                            <Button
                                v-if="!emAberto(slotProps.data.status) && isResponsavelPeloAtendimento(slotProps.data.responsavelId)"
                                label="Finalizar"
                                icon="pi pi-check"
                                iconPos="right"
                                class="p-button-danger h-3rem w-full p-button-rounded p-button-raised"
                                @click="openConfirmDialog(slotProps.data)"
                            ></Button>
                            <div
                                class="bg-primary border-round-3xl h-3rem w-full flex justify-content-center align-items-center text-white"
                                v-if="!emAberto(slotProps.data.status) && !isResponsavelPeloAtendimento(slotProps.data.responsavelId)"
                            >
                                Em Andamento
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
    <DialogChamarPaciente
        v-if="showDialogChamarPaciente"
        v-model:visible="showDialogChamarPaciente"
        :paciente="paciente"
        @onClose="showDialogChamarPaciente = false"
        @onLoad="loadingLoad()"
    />
    <AppConfirmDialog
        v-if="showConfirmDialog"
        v-model:visible="showConfirmDialog"
        :loading="loading"
        :title="'Deseja finalizar o atendimento de ' + this.paciente.name + '?'"
        @onConfirm="finalizarAtendimento"
        @onClose="showConfirmDialog = false"
    >
    </AppConfirmDialog>
</template>
<script>
import BaseService from '@/services/BaseService';
import { getCurrentCompany, getCurrentCustomers } from '@/services/store';
import DropdownAmbiente from '@/components/input/DropdownAmbiente.vue';
import DialogChamarPaciente from './components/DialogChamarPaciente.vue';
import AppConfirmDialog from '@/components/AppConfirmDialog.vue';
import StatusAtendimento from '@/enums/StatusAtendimento';
import { getClientBase } from '@/services/http';
import * as storage from '../../common/storage';
import { getCompany } from '@/services/auth';
import AppInfoManual from '../../components/AppInfoManual.vue';
import TourComponent from '../../components/TourComponent.vue';

export default {
    components: { DropdownAmbiente, DialogChamarPaciente, AppConfirmDialog, AppInfoManual, TourComponent  },
    data() {
        return {
            tenantCustomers: [],
            atendimentos: [],
            paciente: {},
            profile: {},
            service: null,
            ambiente: null,
            loading: false,
            showDialogChamarPaciente: false,
            showConfirmDialog: false,
            empresa: {}
        };
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        },
        currentCustomer() {
            return getCurrentCustomers();
        }
    },
    watch: {
        tenant() {
            this.loadingLoad();
        },
        currentCustomer() {
            this.tenantCustomers = getCurrentCustomers();
            this.loadingLoad();
        }
    },
    async mounted() {
        this.profile = storage.getProfile();
        this.tenantCustomers = getCurrentCustomers();
        this.empresa = await getCompany();
        await this.loadingLoad();
    },
    methods: {
        isResponsavelPeloAtendimento(responsavelId) {
            if (responsavelId == this.profile.id) {
                return true;
            }
            return false;
        },
        temPermissaoAdm() {
            return this.$checkPermission('gestamedica_painel_atendimento_listagem_admin:view');
        },
        temPermissaoChamarFinalizar() {
            return this.$checkPermission('gestamedica_painel_atendimento_chamar_finalizar_atendimentos:editar');
        },
        emAberto(status) {
            if (status == StatusAtendimento.EM_ABERTO) {
                return true;
            }
            return false;
        },
        async loadingLoad() {
            this.loading = true;
            await this.autoLoad();
            this.loading = false;
        },
        async load() {
            try {
                const service = new BaseService('/atendimento_check_in');
                const filtrosExtras = {
                    ambienteId: this.ambiente?.id
                };
                const { data } = await service.findAll({ filtrosExtras });
                this.atendimentos = data;
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Ops, algo de errado aconteceu!', detail: error.message, life: 8000 });
            }
        },
        async autoLoad() {
            await this.load();
            setTimeout(async () => {
                await this.autoLoad();
            }, 5000);
        },
        openDialogChamarPaciente(data) {
            this.paciente = data;
            this.showDialogChamarPaciente = true;
        },
        openConfirmDialog(data) {
            this.paciente = data;
            this.showConfirmDialog = true;
        },
        async finalizarAtendimento() {
            try {
                const dto = {
                    paciente: this.paciente,
                    ambiente: this.ambiente
                };
                await getClientBase().patch(`/atendimento_check_in/finalizar-atendimento`, dto);
                this.$toast.add({ severity: 'success', summary: 'O Atendimento foi finalizado!', life: 8000 });
                this.loadingLoad();
                this.showConfirmDialog = false;
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Ops, algo de errado aconteceu!', detail: error.message, life: 8000 });
            }
        },
        renderTipoHorario(status) {
            switch (status) {
                case StatusAtendimento.EM_ABERTO:
                    return 'Em Aberto';
                case StatusAtendimento.EM_ANDAMENTO:
                    return 'Em Andamento';
                case StatusAtendimento.CONCLUIDO:
                    return 'Concluído';
                default:
                    return 'Não Informado.';
            }
        }
    }
};
</script>
<style scoped lang="scss">
.body {
    transition: all 300ms;
    overflow: auto;
    height: 68vh;
    max-height: 100%;
}

.dropdownAmbiente {
    padding: 0px;
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border-width: 2px;
}
</style>
